

































import Vue from 'vue';
import MembershipResourceViewModel from '@/src/services/viewModel/resource/MembershipResourceViewModel';
import SubscriptionContractResourceViewModel from '@/src/services/viewModel/resource/SubscriptionContractResourceViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    definition: {},
    contactId: '-',
    subscriptionData: new SubscriptionContractResourceViewModel(),
    membership: new MembershipResourceViewModel(),
  }),
  computed: {
    subscriptionId() {
      return this.$route.params.subscriptionId;
    },
    billingContactId() {
      return this.subscriptionData?.billingContact?.contactId;
    },
    memberContactId() {
      return this.membership?.memberContact?.contactId;
    },
    wasContactIdSet() {
      if (this.memberContactId || this.billingContactId) {
        return true;
      }
      return false;
    },
  },
  watch: {
    subscriptionData: {
      immediate: true,
      async handler() {
        this.membership = await this.$service.api.memberships.getMyMembership() as MembershipResourceViewModel;
        this.contactId = this.memberContactId ?? this.billingContactId;
      },
    },
  },
  async mounted() {
    this.definition = await this.$service.api.dynamicForms.getGastroJournalPause();
  },
  methods: {
    onSubmit() {
    },
    getSubscription() {
      return this.$service.api.subscriptions.getMyGastroJournalDetail();
    },
  },
});
